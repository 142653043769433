import { KeycloakOptions } from 'keycloak-angular/lib/core/interfaces/keycloak-options';

const keycloakOptions: KeycloakOptions = {
  config: {
    //url: 'http://localhost:8180',
    url: 'https://accounts.blockcity.tech',
    realm: 'block-dev',
    clientId: 'block-management-frontend-client',
  },
  initOptions: {
    checkLoginIframe: false,
  },
  enableBearerInterceptor: true,
};

export const environment = {
  production: false,
  //baseUrl: 'http://localhost:8080',
  baseUrl: 'https://api.dev.server.blockcity.tech',
  stationManagerBaseUrl: "https://block-station-manager-api.obiwan.xyz",
  googleMapsKey: '',
  keycloakOptions: keycloakOptions,
  adminPageVersion: '1.0.0-3ee124d58e50353fbd1815143e341c41b2da5d2e'

  };
